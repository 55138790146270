import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">
        <p parentName="li">{`PSYS, the Pegasys Protocol token, is live on Syscoin NEVM Mainnet!`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PSYS contract address: `}<a parentName="p" {...{
            "href": "https://explorer.syscoin.org/address/0xE18c200A70908c89fFA18C628fE1B83aC0065EA4"
          }}>{`0xE18c200A70908c89fFA18C628fE1B83aC0065EA4`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`68% of the PSYS genesis supply is allocated to our community members, most of it (50% of the initial supply) aimed at liquidity mining.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`To start, PSYS will be available through six liquidity mining pools: PSYS holders may vote to add more pools once our governance protocol is out.`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h1>
    <p>{`Uniswap proved the product-market fit for highly decentralized financial infrastructures with a platform that has thrived independently, and other DEXes such as Pancakeswap, Quickswap, or Pangolin expanded Uniswap’s vision to other chains with a few tweaks to their products. `}</p>
    <p>{`This first wave of DeFi trading protocols has brought a lot of new concepts and investment opportunities to the general public through extensive experimentation with protocol parameters, rewards, and functionalities. Even so, it’s still too early in DeFi to say for sure what worked or not for these particular DEXes and the balance between speculation and actual value of their governance token is also not that well established yet.`}</p>
    <p>{`More recently, projects like OlympusDAO and Tokemak successfully bootstrapped a new wave of decentralized financial products under the new “DeFi 2.0” umbrella. This new subset of DeFi is aimed at solving the mercenary liquidity problem present in many liquidity farming programs currently by allowing a protocol to become the owner of its own liquidity. `}</p>
    <p>{`The Pegasys team believes this is a very pertinent way of looking at sustainable DEX liquidity but, at the same time, our DEX is going to be the first one on Syscoin EVM so it’s still way too soon to put DeFi 2.0 in place.`}</p>
    <p>{`What we propose for Pegasys is a daring roadmap with extra features to make sure it’s a healthy, expanding protocol with sustainable revenue, recurring users, and good developer support so DeFi on Syscoin can flourish.`}</p>
    <p>{`Lastly, Decentralized Governance is still a very experimental concept with varying degrees of implementation. From MakerDAO’s Core Units that have a lot of autonomy to Pangolin’s off-chain anon devs a DAO can have very different approaches to decentralization and contributions. `}</p>
    <p>{`Pegasys is a protocol initially and continuously developed by Pegasys Labs, so our take on developing the project is similar to what MakerDAO or Uniswap have in place: core contributors that drive the protocol but at the same time having open-source software that anyone can actively be involved with. This approach allows our team to make smaller decisions and deliver features faster with higher code organization and quality while also making sure the community is heard and is driving the protocol forward.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/7a42171b95692334817ecdc0797e9f82/3acf0/defi.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "54%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAwX/xAAXAQADAQAAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAHJplNbjkCv/8QAGRAAAwADAAAAAAAAAAAAAAAAAAESEyEy/9oACAEBAAEFAnoySWh8FNH/xAAYEQADAQEAAAAAAAAAAAAAAAAAARECEv/aAAgBAwEBPwFvVqOz/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAGhAAAQUBAAAAAAAAAAAAAAAAAAECESGBIv/aAAgBAQAGPwJILacuhDDSlP/EABoQAQEBAQEBAQAAAAAAAAAAAAEhADFxEfD/2gAIAQEAAT8hRAkk65LFD3QPj6ZcSFJkp+c3Ogb/2gAMAwEAAgADAAAAEAvv/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARQf/aAAgBAwEBPxBQTC30L//EABYRAQEBAAAAAAAAAAAAAAAAAAEAEf/aAAgBAgEBPxAQLb//xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMYFRYZH/2gAIAQEAAT8QaIyUAqSG+Dnfox48TYG29YcEDRpfuK2EWg0oVwGoRVOmHl8TP//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Pegasys is the pivotal LEGO block in Syscoin's DeFi landscape.",
              "title": "Pegasys is the pivotal LEGO block in Syscoin's DeFi landscape.",
              "src": "/static/7a42171b95692334817ecdc0797e9f82/e5166/defi.jpg",
              "srcSet": ["/static/7a42171b95692334817ecdc0797e9f82/f93b5/defi.jpg 300w", "/static/7a42171b95692334817ecdc0797e9f82/b4294/defi.jpg 600w", "/static/7a42171b95692334817ecdc0797e9f82/e5166/defi.jpg 1200w", "/static/7a42171b95692334817ecdc0797e9f82/d9c39/defi.jpg 1800w", "/static/7a42171b95692334817ecdc0797e9f82/3acf0/defi.jpg 2000w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Pegasys is the pivotal LEGO block in Syscoin's DeFi landscape.`}</figcaption>{`
  `}</figure></p>
    <h1 {...{
      "id": "vision",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vision",
        "aria-label": "vision permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vision`}</h1>
    <p>{`With all that in mind, our vision for `}<strong parentName="p">{`PSYS`}</strong>{` is simple:`}</p>
    <blockquote>
      <p parentName="blockquote">{`A fair Governance Token with embedded utility and flexibility to let the community successfully drive the Pegasys Protocol alongside Pegasys Labs’ core contributors.`}</p>
    </blockquote>
    <p>{`After looking into other DEX tokenomics and DAOs born out of them, we can take a few examples of what we don’t believe is constructive for PSYS: `}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Stale tokenomics unable to react to new market conditions & community demands;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Little distribution of governance tokens to the community not allowing them to create proposals;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`28-year liquidity mining programs;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Drastic reenvisionings of tokenomics with extreme burn amounts to fix lack of long-vision clarity;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Minting and burning tokens simultaneously instead of upgrading the protocol so the burn amount can be used for marketing via an opaque metric;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tokens vested directly to personal wallets of devs who are arguably inactive and will very likely dump the allocation on the market eventually;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Too large of a focus on financial speculation with little governance or utility value to a protocol token.`}</p>
      </li>
    </ul>
    <p>{`The main purpose of PSYS will always be to allow anyone who’s deeply involved to be able to steer the boat of the Pegasys Protocol. While we don’t want to overshadow said governance aspect with financial incentives, we’re aware that many users will eventually want to (fairly) take profit over their early PSYS positions. `}</p>
    <blockquote>
      <p parentName="blockquote">{`We believe this price action can be sustainably achieved through active development, new features, as well as deflationary and volume-positive events instead of more aggressive token emissions.`}</p>
    </blockquote>
    <p>{`Pegasys will follow Uniswap’s principals of `}<strong parentName="p">{`neutrality and trust minimization`}</strong>{`: it is crucial that governance is constrained to where it is strictly necessary. With this in mind, the Pegasys governance framework is limited to contributing to both protocol development and usage as well as the development of the broader Pegasys ecosystem.`}</p>
    <p>{`In doing so, `}<strong parentName="p">{`PSYS officially enshrines Pegasys as publicly-owned and self-sustainable infrastructure from the get-go while continuing to carefully protect its indestructible and autonomous qualities`}</strong>{`.`}</p>
    <h1 {...{
      "id": "general-token-info",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#general-token-info",
        "aria-label": "general token info permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`General Token Info`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Symbol`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`PSYS`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Decimals`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ERC20`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`ERC2612`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Governance`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Native Network`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Syscoin NEVM`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Initial Supply`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100,000,000`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mintable`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes (onlyOwner)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Mint Lock`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yearly`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Burnable`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Yes`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Upgradeable`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Blacklistable`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`No`}</td>
        </tr>
      </tbody>
    </table>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3809b4462652e33e6b939581f9a22411/7f15f/Genesis.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "72%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAABYlAAAWJQFJUiTwAAACsUlEQVQ4y32TXUhTYRjHvesmMoIgKLGkyL6g7KIPwdLIaUEUmTdFdxWUGRVBNwVdGSRkqYVSRplZaRKhKa61XDubuilNGSSJeHRuLl1h6uZ2zvEX5xy3ZqUPPLzn5X3O7/0/H2/C7OwsqgWDQURRZEgcQhwU8fq9+HwefMPDeEZGkGRZi1Pjo87cv/GWEAXKsszU5BQSEaRZifGBMfr7fXwLBJgJBrUY5S+Yug9FIoRCIYKhEIqi6EDd9RtEm4dXZxqp3P6Iy5caONLVzRVHL+bRsZgKJZqVpOASR+jv62N01P9HoaLoAR0VToo2llOcVsHdpPtcPF/HPqGdjGYL6S0WyvsGYmmrFgkreMQAfr+PcDisAxVZ0T5sFi83UsopSy3lTlolJevKKLxQR5a9g1yjQI7Jxq7mNhqGvHqJ5jJTJFlLNXpRAuj12HvVTZLhE4W7X3BvcxmlyfcpKKgny9aBoVUHHjBaybd0MhmR5imNb1aCunENTLMy38HS/C4S851k5jRzO+UhV869JtOuA7NNAgaTjf1GK7bvgXkq410DNnX+ZPVJJ8mnnSw/7mBJnosUg4WjN5vIttrJNgoc/CBwxNxOrkng3bBPA0qK8n+gpXeCZcccJB7tYsUJB2sKzKwtbmDnm/fkfrRrMFVhrslGptFKq1fvqKwsoDDwS2LD2S5WFX4g9V4DW5/WsKXqGbvrGskxt5OtAW3aqtZycHL6n7mMAeW5DhV9drG6oood1bVsrXpJauVzdsUBD3+0s6eljVs9XxeEacDokIbkCHlvW1hfUU16zRs2P6phb30Th8ztGEyCBjtldTI+E14cGH1Cqk3MhLlmFtj0+AVJD56wrfYtGUaBzNbPXO924w/NxLrLQsDYW46bKfd4gMcuNyVf3NQOeuj5MRE7WwymAn8DWL+56LhefE0AAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Simplified Genesis Allocation",
              "title": "Simplified Genesis Allocation",
              "src": "/static/3809b4462652e33e6b939581f9a22411/c1b63/Genesis.png",
              "srcSet": ["/static/3809b4462652e33e6b939581f9a22411/5a46d/Genesis.png 300w", "/static/3809b4462652e33e6b939581f9a22411/0a47e/Genesis.png 600w", "/static/3809b4462652e33e6b939581f9a22411/c1b63/Genesis.png 1200w", "/static/3809b4462652e33e6b939581f9a22411/7f15f/Genesis.png 1530w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Simplified Genesis Allocation`}</figcaption>{`
  `}</figure></p>
    <h1 {...{
      "id": "genesis-allocation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#genesis-allocation",
        "aria-label": "genesis allocation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Genesis Allocation`}</h1>
    <p><strong parentName="p">{`100 million PSYS have been minted at the start and will become accessible over the course of 2 years`}</strong>{`. The genesis allocation goes as follows:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`50.00% to Pegasys users via liquidity mining `}<inlineCode parentName="p">{`50,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`15.00% to a treasury governed by PSYS holders `}<inlineCode parentName="p">{`15,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2.00% to Pegasys community members `}<inlineCode parentName="p">{`2,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1.00% to Syscoin community members `}<inlineCode parentName="p">{`1,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`10.00% to team members with 16-months vesting `}<inlineCode parentName="p">{`10,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`3.00% for strategic partnerships vested over 6 months `}<inlineCode parentName="p">{`3,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`2.00% for marketing vested over 4 months `}<inlineCode parentName="p">{`2,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`1.00% for general events vested over 4 months `}<inlineCode parentName="p">{`1,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`10.00% for seed investors vested over 1 year `}<inlineCode parentName="p">{`10,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`6.00% for future investors vested over 16 months `}<inlineCode parentName="p">{`6,000,000 PSYS`}</inlineCode></p>
      </li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f3081d52b65bda72622b70c31bc65600/56caf/Breakdown.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "58.333333333333336%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAAChUlEQVQoz22TW0iTYRjHdxF03YWEQUVI7sJTpkR2AC8sEK+CoAPSTdFNBNFd3URdFGImIU1FScNIO5Gl6JLUxVwe5mFjqMvQalN3+NycY4fv+7Zvv/g+dXjogfflPTzvn//z/v+PLpVKEY/HEUWRaDRKJBIhFoshxkVi0RiRcIRINEZUFEkqKdT8rWNn6NTJ6/XicrnweDzr678uXG4XK6sC/jU/Ps8iUa83/Si5BWwnqM7n8xEMBkkkEtqBspGgyAoLY25GX03yvv8nTStBBldCRJPJdN7/QHUqs1AohCRJiKIESoK57mleX2ynpqSR+wcfU367g4wxG9mmScqsM3T6AmmgnUPndDoJh8NIkqyBTf4KUnvLSFNpCy/PNGMoqqfyQQ+5jjkKzDayzVMc+j5B25KwraI0Q7fbrZWcSimEI3EqqkyUPh3h5nMrD+/0UVVQTWllNQeMA+QN2Smw2NGbbZwacRBQSaRSiJKELMsoioJOVXYzZl0CGVcM7KmoYe8FA/uut1FV/QXnxAx3TRaOmibI+2EnZ8jGMYsd22oYkkmEQABVC9UpGkNV2WRCZn7Rx/7LBjJvvKPwURfFzb1cNY9S51rmkmMBvXmKvCEbucMO8k1WpoXAbtuoVhEEQfNgQpK59uwTZzv6OD9o4XRvP0UfejjSNUCW2Ua+xc5x6yyHB62UdxpJyPI2xTVR/H6/Zhl5wzbznmXKXjSQVd+qgZV87uPEwAg5ww70pnEyP36lsKWdqcWldU8qyjbFdWrd6meqm81LYW2NJ929nKtrpLjWQE5DK/rWt5xsecM94zcWV1d3gW3rlK2hbCSpoao3t+xh/PcfZjxerf3S3bKD2SbgP1dHQQF+j8zXAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Genesis Allocation Breakdown",
              "title": "Genesis Allocation Breakdown",
              "src": "/static/f3081d52b65bda72622b70c31bc65600/c1b63/Breakdown.png",
              "srcSet": ["/static/f3081d52b65bda72622b70c31bc65600/5a46d/Breakdown.png 300w", "/static/f3081d52b65bda72622b70c31bc65600/0a47e/Breakdown.png 600w", "/static/f3081d52b65bda72622b70c31bc65600/c1b63/Breakdown.png 1200w", "/static/f3081d52b65bda72622b70c31bc65600/56caf/Breakdown.png 1712w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Genesis Allocation Breakdown`}</figcaption>{`
  `}</figure></p>
    <h1 {...{
      "id": "power-balance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#power-balance",
        "aria-label": "power balance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Power Balance`}</h1>
    <p>{`There are three main stakeholder categories in our tokenomics, in order of allocation: `}<strong parentName="p">{`community, team & investors`}</strong>{`.`}</p>
    <p>{`We, above all else, believe that Pegasys should be a protocol built by the community for the community. That’s why we made sure the community-governed and openly-accessible portion of the tokens were the most significant one, ensuring a fair and open governance model as well as strong incentives for active users and contributors.`}</p>
    <p>{`Similar to Uniswap Labs’ case, when Pegasys leverages Pegasys Labs as core contributors the direct result is a bolder roadmap (which will be announced soon), higher-quality & concise code, and a stronger vision of where the protocol should be headed. The plan is to gradually dissolve this influence and control from Pegasys’ Labs end into the broader community & active contributors. Especially in earlier stages, we can move way faster as a whole and structure Pegasys in a considerably more robust manner when partnering with Pegasys Labs.`}</p>
    <p>{`Last but not least, we have the investors. Smart money - investors, VCs, and angels that can bring in reach, users, liquidity, and hype to the protocol - enables Pegasys to evolve more rapidly and vigorously with powerful partnerships. This ultimately ends up preparing the protocol for long-term livelihood and future expandability from a more traditional, startup-like, sense. We’re concluding a small seed round lead by Syscoin Foundation alongside strategic VCs that will provide much-need growth for Pegasys in the early stages parallel to the guarantee that core contributors & operations are sustained in the mid-term. Similar to what `}<a parentName="p" {...{
        "href": "https://traderjoe-xyz.medium.com/trader-joe-raises-5-million-in-strategic-sale-led-by-defiance-capital-gbv-and-mechanism-capital-a40646ffdff0"
      }}>{`TraderJoe.xyz did`}</a>{`, a portion of the tokens are allocated for future investment rounds PegasysDAO can promote, bringing in even more robustness to its ownership structure and outspread DeFi positioning within the ecosystem. `}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "720px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3101946459e47fd33a816115254130a7/80e3c/Partnership.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAADBf/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAETuYkULZSH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMEEiL/2gAIAQEAAQUC10BnQIcW2Nqndl29f//EABYRAAMAAAAAAAAAAAAAAAAAAAECEP/aAAgBAwEBPwFTP//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAYEAADAQEAAAAAAAAAAAAAAAAAEBEhQf/aAAgBAQAGPwLeEMVX/8QAGRABAAMBAQAAAAAAAAAAAAAAAQARMSFR/9oACAEBAAE/IULGLnE7sBGlR+zyoKwGRVT/2gAMAwEAAgADAAAAEMAf/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAEDAQE/EADJTb//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREh/9oACAECAQE/EEdNP//EABwQAQACAwADAAAAAAAAAAAAAAEAESExQVFhgf/aAAgBAQABPxCj5sPv53NQdYUUarGXXI5Nul7C20VKmrl7JGA6xW/Memf/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Pegasys will leverage the best of three worlds - community, developers & investors - to deliver a DeFi powerhouse",
              "title": "Pegasys will leverage the best of three worlds - community, developers & investors - to deliver a DeFi powerhouse",
              "src": "/static/3101946459e47fd33a816115254130a7/80e3c/Partnership.jpg",
              "srcSet": ["/static/3101946459e47fd33a816115254130a7/f93b5/Partnership.jpg 300w", "/static/3101946459e47fd33a816115254130a7/b4294/Partnership.jpg 600w", "/static/3101946459e47fd33a816115254130a7/80e3c/Partnership.jpg 720w"],
              "sizes": "(max-width: 720px) 100vw, 720px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Pegasys will leverage the best of three worlds - community, developers & investors - to deliver a DeFi powerhouse`}</figcaption>{`
  `}</figure></p>
    <h1 {...{
      "id": "liquidity-mining",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#liquidity-mining",
        "aria-label": "liquidity mining permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Liquidity Mining`}</h1>
    <p><strong parentName="p">{`Pegasys is paving the way for DeFi inside Syscoin.`}</strong>{` For that, we propose a relatively aggressive DeFi 1.0 24-month liquidity mining strategy so not only Pegasys can build TVL, but Syscoin and other dApps within can get exposure and user retention. With fairness in mind, early adopters are rewarded relative to the amount of volume brought to the protocol, but any user picking up Pegasys throughout the first year will still get significant rewards.`}</p>
    <p>{`We’re also allocating 3% of the total supply to be distributed as rewards for PSYS stakers, 5% of the total supply for strategic incentives and liquidity provisioning, as well as 5% for future protocol extensions we’ll release. This means upcoming dApps can partner with Pegasys to bring more mining opportunities to their community without impacting other Pegasys pool rewards.`}</p>
    <h2 {...{
      "id": "base-rewards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#base-rewards",
        "aria-label": "base rewards permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Base Rewards`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Period`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Share`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tokens`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PSYS/block`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`PSYS/min`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 1 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`8,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`456`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`182`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 2 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`285`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`114`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 3 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3.5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3,500,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`199`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`79`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 4 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`171`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`68`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 5 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2,300,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`131`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`52`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 6 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`114`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 7 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.9%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,900,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`108`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`43`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 8 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.7%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,700,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`97`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`38`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 9 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,500,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`85`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 10 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,300,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`74`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`29`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 11 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.1%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,100,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`62`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 12 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`57`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 13 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.9%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`900,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`51`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`20`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 14 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.8%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`800,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`18`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 15 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.7%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`700,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`39`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 16 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.6%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`600,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`34`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`13`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 17 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`28`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 18 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.4%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`400,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`22`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`9`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 19 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`300,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`17`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 20 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.2%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`200,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`11`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 21 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.1%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 22 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.09%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`90,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 23 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.08%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`80,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 24 basis`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.03%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`30,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`37%`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`37,000,000`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`88 (avg)`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`35 (avg)`}</strong></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "further-rewards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-rewards",
        "aria-label": "further rewards permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Further Rewards`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Use`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Share`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Tokens`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Start`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Duration`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`PSYS Staking`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`3,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`6 months`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Strategic Liquidity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day 1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`12 months`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Protocol Extension`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5%`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5,000,000`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Month 6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10 months`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`An initial liquidity mining program will go live on April 27th 2022. The initial program will run until April 27th 2024, and will start with the following six pools on Pegasys:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`SYS/USDC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS/WETH`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS/WBTC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS/PSYS`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`PSYS/USDC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`USDC/USDT`}</p>
      </li>
    </ul>
    <p>{`16,500,000 PSYS will be allocated per pool to LPs proportional to liquidity over the first three months, which roughly translates to, on average:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`136,138 PSYS per pool per day.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`236.4 PSYS per pool per block (2.5 min block time)`}</p>
      </li>
    </ul>
    <p>{`These PSYS are not subject to vesting or lock up.`}</p>
    <p>{`Once the governance portion of the protocol is released, holders can vote to allocate PSYS towards grants, strategic partnerships, governance initiatives, additional liquidity mining pools, and other programs. Before that, Pegasys Labs & the Pegasys Community can propose to incentivize/drop additional pools with strategic relevance and utility to the protocol in a more informal, off-chain, governance process.`}</p>
    <p>{`As a quick trivia, we’ve loosely modeled the cummulative token distribution around the following parabolic equation, alongside some added harmonics specific to each case so released tokens are more considerable in the start period:`}</p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0.085`}</mn><mo parentName="mrow">{`∗`}</mo><msup parentName="mrow"><mi parentName="msup">{`x`}</mi><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`4.2`}</mn><mo parentName="mrow">{`∗`}</mo><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`y = -0.085 * x^{2} + 4.2 *x`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`y`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.72777em",
                  "verticalAlign": "-0.08333em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`−`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`0`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`8`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`5`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`∗`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.897438em",
                  "verticalAlign": "-0.08333em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathdefault"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.8141079999999999em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-3.063em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`+`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.64444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`4`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`.`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`2`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`∗`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222222222222222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.43056em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`x`}</span></span></span></span></span></p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`y`}</mi><mo parentName="mrow">{`:`}</mo><mi parentName="mrow">{`r`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`l`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`a`}</mi><mi parentName="mrow">{`s`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`d`}</mi><mtext parentName="mrow">{`  `}</mtext><mi parentName="mrow">{`P`}</mi><mi parentName="mrow">{`S`}</mi><mi parentName="mrow">{`Y`}</mi><mi parentName="mrow">{`S`}</mi><mtext parentName="mrow">{`  `}</mtext><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`k`}</mi><mi parentName="mrow">{`e`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`s`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`y:released\\ \\ PSYS\\ \\ tokens`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.625em",
                  "verticalAlign": "-0.19444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.03588em"
                }
              }}>{`y`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.69444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.02778em"
                }
              }}>{`r`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.01968em"
                }
              }}>{`l`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`s`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`d`}</span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.13889em"
                }
              }}>{`P`}</span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.22222em"
                }
              }}>{`Y`}</span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.05764em"
                }
              }}>{`S`}</span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mspace"
              }}>{` `}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathdefault",
                "style": {
                  "marginRight": "0.03148em"
                }
              }}>{`k`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`e`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`s`}</span></span></span></span></span></p>
    <p><span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi><mo parentName="mrow">{`:`}</mo><mi parentName="mrow">{`m`}</mi><mi parentName="mrow">{`o`}</mi><mi parentName="mrow">{`n`}</mi><mi parentName="mrow">{`t`}</mi><mi parentName="mrow">{`h`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x: month`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.43056em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`:`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2777777777777778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.69444em",
                  "verticalAlign": "0em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`m`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`o`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`n`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`t`}</span><span parentName="span" {...{
                "className": "mord mathdefault"
              }}>{`h`}</span></span></span></span></span></p>
    <h1 {...{
      "id": "airdrops",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#airdrops",
        "aria-label": "airdrops permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Airdrops`}</h1>
    <p>{`There will be two PSYS airdrops happening simultaneously on April 27th:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Syscoin Community Airdrop`}</strong>{`: Aimed at the general Syscoin community, as well as early adopters of the NEVM. A total of `}<inlineCode parentName="li">{`1,000,000 PSYS`}</inlineCode>{` will be distributed, and more details on how to participate will be released soon.`}</li>
      <li parentName="ul"><strong parentName="li">{`Pegasys Community Airdrop`}</strong>{`: Aimed at early users of the Pegasys DEX. Users who add significant liquidity to the protocol (leveraging `}<a parentName="li" {...{
          "href": "https://app.multichain.org/#/router"
        }}>{`Multichain’s officially bridged tokens`}</a>{` and $SYS) and/or perform multiple considerable swaps in the DEX from February 16th to March 10th 2022 will be eligible for a proportional $PSYS airdrop. Specific amounts are hard to predict since it also depends on the volume of other user interactions, but the total amount distributed will be `}<inlineCode parentName="li">{`2,000,000 PSYS`}</inlineCode>{`.`}</li>
      <li parentName="ul"><strong parentName="li">{`Future Airdrops`}</strong>{`: There are more airdrops to be announced, and the rules to be eligible to it are the same from the first one: you just need to add significant liquidity to the protocol (leveraging Multichain’s officially bridged tokens and $SYS) and/or perform multiple considerable swaps in the DEX.`}</li>
    </ul>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e36217add0590aedfbaf365c58e5037e/c58a3/Airdrop.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABcBAQEBAQAAAAAAAAAAAAAAAAIAAQP/2gAMAwEAAhADEAAAAc+niWQygqtjeUxX/8QAGxABAAMBAAMAAAAAAAAAAAAAAQACAxEEEkH/2gAIAQEAAQUC8a7bTT1vlBZqXqT4PROT/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BAjP/xAAXEQADAQAAAAAAAAAAAAAAAAABAiAh/9oACAECAQE/AQ2R/8QAHRAAAgIBBQAAAAAAAAAAAAAAASEAEAIREiIxQf/aAAgBAQAGPwLlkhM9ex7Sg3FG26//xAAaEAEAAwEBAQAAAAAAAAAAAAABABFBIRBR/9oACAEBAAE/IQolF19l247EMAikTSX0uHmI9OGwlQT/2gAMAwEAAgADAAAAEKz3v//EABgRAAMBAQAAAAAAAAAAAAAAAAABESEx/9oACAEDAQE/EFcmlORNw//EABYRAQEBAAAAAAAAAAAAAAAAAAEQIf/aAAgBAgEBPxAhZCf/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUFhcbH/2gAIAQEAAT8QvERpb4njbTnxYNiACxKSXEq8nk/eYV2L8YUUBFmYxtqzqU9Ul7P/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Make sure to use the DEX in the early days!",
              "title": "Make sure to use the DEX in the early days!",
              "src": "/static/e36217add0590aedfbaf365c58e5037e/e5166/Airdrop.jpg",
              "srcSet": ["/static/e36217add0590aedfbaf365c58e5037e/f93b5/Airdrop.jpg 300w", "/static/e36217add0590aedfbaf365c58e5037e/b4294/Airdrop.jpg 600w", "/static/e36217add0590aedfbaf365c58e5037e/e5166/Airdrop.jpg 1200w", "/static/e36217add0590aedfbaf365c58e5037e/c58a3/Airdrop.jpg 1500w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Make sure to use the DEX in the early days!`}</figcaption>{`
  `}</figure></p>
    <h1 {...{
      "id": "release-schedule",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#release-schedule",
        "aria-label": "release schedule permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Release Schedule`}</h1>
    <p>{`Over the 2-year plan, the overall PSYS token release goes as follows:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/b49a41027b5bbb6aa393565c268091a4/6c9f4/Release.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "41.66666666666667%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABP0lEQVQoz32Rf0/jMAxA+/2/GevGCY0xAZNuoDtGt/6gaRI7zZp3yopOnA7N0vvHkZ/tuADw3lPXNW3b0jQNIpLTpJSI45kYI2M8M47xkrsWRS5W1b+Cr0xTQl1AVfDjiEQlkYhhQoeI9COuVmwl2KPgu0CRu4cQvpV9nSbKGX9U2uee40PD+/2Jt3XFYVPx/njksK2o982/wiyZspBP0ZQIndLtew6PJ153FS+/Tvw8Njy3LU99z9YYNsawNoadcxRZlteeFSnvTdIztvb83nc87Rvuq447M/DDOUrnuXFC6ZSFnSmtcmOFjQ8U+f8uR5gSdgi8Vpb1W8+qMiyMYyFK6ZWFmwuXF+Q/Sis8ZGGeK19v1wysakPZW5bWs3Ke28Fza9zMcJ2lcWydzELVwIcIQwhYVQb5RK/wzbvTwB+WamVA0O6u1QAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Genesis Release Schedule",
              "title": "Genesis Release Schedule",
              "src": "/static/b49a41027b5bbb6aa393565c268091a4/c1b63/Release.png",
              "srcSet": ["/static/b49a41027b5bbb6aa393565c268091a4/5a46d/Release.png 300w", "/static/b49a41027b5bbb6aa393565c268091a4/0a47e/Release.png 600w", "/static/b49a41027b5bbb6aa393565c268091a4/c1b63/Release.png 1200w", "/static/b49a41027b5bbb6aa393565c268091a4/d61c2/Release.png 1800w", "/static/b49a41027b5bbb6aa393565c268091a4/6c9f4/Release.png 2326w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Genesis Release Schedule`}</figcaption>{`
  `}</figure></p>
    <p>{`A more detailed breakdown is presented on this chart:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e713f86dad504dee2055eabb5ac519be/2a50e/ReleaseBreakdown.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "40.666666666666664%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABa0lEQVQoz3WRi27aQBBF+f9/qipFKqJpLPwCBDh48QtsQ2rA6yf2qdbgKK3Uka7uPmaOZmcnXddxuVxIkoTz+czpdCJNU+I4pq5rVHRdT9/3/D96FEflTMqyHArVRh1+1b3rhuRnDadrg4glK/+K9n5mtknQdmfC05Us+/0AKtgIHKVgPDsqG9jGOT+2Ed+Wa76vF0x3Npow0fc6drDAPYakSUrbtn8Dv4JaYJdmvG4cjK3OXszJPJPcNSkcg8oxqR2T1l1xC2PCKBo4k6ZpUBrj3vUc0hua6TLXl7i2g7A9HCtgaR2w7APm4oCxPKIvjtjrFBF/IISLlJJJWVW0TUNZdWxExsz0eTFcZivB23vEdB8y8x96DXzeQp9fygPlHvMoIK+r4bnDDFVXuaz4aftMly6aEFiBjxH6GP4ey1PyPmX+o4Uf0N7vj38bgbIoucqcoi7Ii4KbLMilfK4lt9FHFcXnnfJh9k/gH94dW6e3jKyoAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Genesis Release Breadown",
              "title": "Genesis Release Breadown",
              "src": "/static/e713f86dad504dee2055eabb5ac519be/c1b63/ReleaseBreakdown.png",
              "srcSet": ["/static/e713f86dad504dee2055eabb5ac519be/5a46d/ReleaseBreakdown.png 300w", "/static/e713f86dad504dee2055eabb5ac519be/0a47e/ReleaseBreakdown.png 600w", "/static/e713f86dad504dee2055eabb5ac519be/c1b63/ReleaseBreakdown.png 1200w", "/static/e713f86dad504dee2055eabb5ac519be/d61c2/ReleaseBreakdown.png 1800w", "/static/e713f86dad504dee2055eabb5ac519be/2a50e/ReleaseBreakdown.png 2352w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Genesis Release Breadown`}</figcaption>{`
  `}</figure></p>
    <p>{`Please note that tokens being released from vesting do not necessarily translate to circulating supply. It essentially just means that, from that point on, Pegasys Labs & the Pegasys DAO can choose to leverage that allocation.`}</p>
    <p>{`This is the reason “Future Investors” is marked with an asterisk, as this particular token usage is subject to DAO voting, market/business momentum, and protocol consensus on raising additional investments.`}</p>
    <p>{`Similarly, the “Treasury” allocation is marked with a double asterisk since the values presented on the chart are a mere representation/projection of how the community treasury could be leveraged in the long term, as it’s impossible to predict how the governance processes will go futurely.`}</p>
    <h1 {...{
      "id": "inflation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#inflation",
        "aria-label": "inflation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Inflation`}</h1>
    <p>{`A `}<strong parentName="p">{`fixed inflation rate of 3% per year will start after the first year`}</strong>{`, ensuring continued participation and contribution to Pegasys, governed by PSYS holders, as well as giving enough flexibility for the protocol to pivot and expand as necessary. `}</p>
    <p>{`Due to our current positioning inside the Syscoin Ecosystem as a pioneer project, there will likely be community demands to expand Pegasys’ functionalities into other fields of DeFi and DAOs such as lending/borrowing, token locking, multisig dashboards & DeFi 2.0 asset reserves.`}</p>
    <p>{`With an aggressive 2-year liquidity mining plan, the Pegasys Protocol will need the option to mint more tokens futurely. This is just a possibility though: tokens minted on subsequent years are susceptible to Pegasys holders discretion and shall be used as the DAO best sees fit.`}</p>
    <p>{`If the community votes on burning all the inflationary supply for that year, that’s the decision, but if the community agrees on leveraging more tokens for a new liquidity mining program or protocol extension such as lending, then the PegasysDAO still has enough tokens to expand its user base.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Max Inflation Year 2: 3% `}<inlineCode parentName="p">{`3,000,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Max Inflation Year 3: 3% `}<inlineCode parentName="p">{`3,090,000 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Max Inflation Year 4: 3% `}<inlineCode parentName="p">{`3,182,700 PSYS`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Max Inflation Year 5: 3% `}<inlineCode parentName="p">{`3,278,181 PSYS`}</inlineCode></p>
      </li>
    </ul>
    <h1 {...{
      "id": "deflation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#deflation",
        "aria-label": "deflation permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Deflation`}</h1>
    <p>{`The initial phase of the Pegasys Protocol is aimed at distributing tokens fairly and getting the community started on leading future development. No deflationary mechanisms are in place from the get-go, but it can be achieved through different methods:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Some portions of the tokenomics can be voted to go towards burns;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The protocol fee structure can be eventually changed to have built-in buy-back-and-burns;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Future inflation events can be voted out to put immediate burns instead of mints;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Future protocol extensions and features can implement deflationary mechanisms.`}</p>
      </li>
    </ul>
    <p>{`As previously mentioned, an inflationary tokenomics is in place only for cases of actual necessity such as protocol extensions. We believe it’s best to have minting options in place from the beginning even if they don’t end up being completely leveraged as it’s more transparent and seamless towards the community.`}</p>
    <h1 {...{
      "id": "governance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#governance",
        "aria-label": "governance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Governance`}</h1>
    <p>{`A community-managed protocol opens up a world of infinite possibilities. We hope to see a variety of experimentation, including partner incentives and expansive funding, both of which can foster additional Syscoin ecosystem growth.`}</p>
    <p>{`The governance portion of our protocol is still being wrapped up but, even so, it’s still interesting to put a grace period in place so PSYS tokens can be more fairly distributed and Syscoin community members can become more present in the protocol. This grace period also provide the Pegasys community enough time to familiarize itself with the governance system, bring in `}<strong parentName="p">{`a diverse and high-quality set of protocol delegates,`}</strong>{` and begin discussions and communications around potential governance proposals.`}</p>
    <p>{`PSYS holders are responsible for ensuring that governance decisions are made in compliance with applicable laws and regulations. The community is encouraged to consult knowledgeable legal and regulatory professionals before implementing any specific proposal.`}</p>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`Liquidity providers in the preliminary set of targeted pools can deposit their liquidity tokens already to anticipate positioning for farming. Mining will begin on April 27th 2022.`}</p>
    <p>{`Users are also able to use the full DEX capabilities to take part in the airdrop.`}</p>
    <p>{`Please do join our `}<a parentName="p" {...{
        "href": "https://discord.gg/Pegasys"
      }}>{`Discord channel`}</a>{` to be part of our community and stay in the loop regarding Pegasys DEX and Pegasys DAO.`}</p>
    <h2 {...{
      "id": "edit-notes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#edit-notes",
        "aria-label": "edit notes permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Edit notes`}</h2>
    <ul>
      <li parentName="ul">{`May 4th 2022: Change PSYS/ETH farm to USDC/USDT and fix seed round allocation size.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      